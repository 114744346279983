<template>
  <div class="contacts-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-4">
                <div class="dataTables_length d-inline">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="datatable.queries.per_page"
                      class="ml-1 mr-1"
                      size="sm"
                      :options="[10, 15, 25, 50, 100, 'All']"
                      @change="getContacts"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <div class="col-sm-12 col-md-8">
                <div class="dataTables_filter text-md-right">
                  <a v-if="user.business.is_enable_import_contact" class="btn btn-primary btn-sm mr-2" @click="handleShowModalImport" >
                    <i class="uil uil-upload-alt"></i> Import
                  </a>
                  <a class="btn btn-primary btn-sm mr-2" @click="handleExportModalOpen" >
                    <i class="uil uil-download-alt"></i> Export
                  </a>
                  <router-link
                    :to="{ name: 'business.contacts.create' }"
                    class="btn btn-primary btn-sm"
                  >
                    <i class="uil uil-plus"></i> New Contact
                  </router-link>
                </div>
              </div>
            </div>
            <div class="row mb-md-2">
              <div class="col-sm-12 col-md-4">
                <div class="d-inline ml-2">
                  <b-dropdown
                    variant="black"
                    left
                    toggle-class="btn btn-primary btn-sm"
                  >
                    <template v-slot:button-content><span v-b-tooltip.hover title="Apply to All Contacts If No Contacts are selected">Actions <i class="uil uil-question-circle"></i></span></template>

                    <b-dropdown-item @click="bulkAssignKeywords">
                      <i class="uil uil-tag mr-2"></i> Apply Keywords
                    </b-dropdown-item>

                    <b-dropdown-item @click="onBulkStartAutomation">
                      <i class="uil uil-bolt mr-2"></i> Run Automation
                    </b-dropdown-item>

                    <b-dropdown-item @click="bulkDeteteContacts">
                      <i class="uil uil-trash mr-2"></i> Delete
                    </b-dropdown-item>
                  </b-dropdown>
                  <span v-if="selectedContacts.length" class="pl-2">
                    <strong>{{ selectedContacts.length }}</strong> selected
                  </span>
                </div>
              </div>
              <div class="col-sm-12 col-md-8">
                <div class="d-flex align-items-center float-right">
                  Keyword:
                  <select
                    v-model="datatable.queries.keyword"
                    class="form-control ml-2 form-control-sm"
                  >
                    <option value="">All</option>
                    <option
                      v-for="keyword in keywords"
                      :key="keyword.id"
                      :value="keyword.id"
                      >{{ keyword.name }}</option
                    >
                  </select>
                  <select
                    v-model="datatable.queries.filter"
                    class="form-control ml-2 form-control-sm"
                  >
                    <option value="">All</option>
                    <option value="optin">Opt In</option>
                    <option value="optout">Opt Out</option>
                    <option v-if="isDoubleOptin" value="verified">SMS Verified</option>
                    <option v-if="isDoubleOptin" value="not_verified">SMS Not Verified</option>
                  </select>
                  <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                  <button
                    class="ml-1 btn btn-primary btn-sm"
                    @click="getContacts"
                  >
                    <i class="uil uil-search-alt"></i>
                  </button>
                  <button
                    class="ml-1 btn btn-primary btn-sm d-flex"
                    @click="clickRest"
                  >
                    <i class="uil uil-refresh"></i><span class="pl-1">Reset</span>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="filterText" class="col-sm-12 text-center py-2">
              <!-- eslint-disable vue/no-v-html -->
              <span class="font-weight-bold text-primary" v-html="filterText"></span>
              <!--eslint-enable-->
            </div>
            <template v-if="!loadingRedemptions">
              <b-table
                :items="contacts"
                :fields="datatable.columns"
                :sort-by.sync="datatable.queries.sort_by"
                :sort-desc.sync="datatable.queries.sort_desc"
                :sort-direction="datatable.queries.sort_direction"
                :per-page="datatable.queries.per_page"
                :no-local-sorting="true"
                @sort-changed="sortChanged"
              >
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
                <template v-slot:head(id)>
                  <div
                    class="custom-control custom-checkbox d-inline custom-checkbox-xs"
                  >
                    <input
                      id="bulk-contacts"
                      type="checkbox"
                      class="custom-control-input"
                      @change="toggleBulkContacts($event)"
                    />
                    <label
                      class="custom-control-label"
                      for="bulk-contacts"
                    ></label>
                  </div>
                </template>
                <template v-slot:cell(id)="data">
                  <div
                    class="custom-control custom-checkbox d-inline custom-checkbox-xs"
                  >
                    <input
                      :id="`item-${data.item.id}`"
                      v-model="selectedContacts"
                      type="checkbox"
                      class="custom-control-input"
                      :value="data.item.id"
                    />
                    <label
                      class="custom-control-label"
                      :for="`item-${data.item.id}`"
                    ></label>
                  </div>
                </template>
                <template v-slot:cell(name)="data">
                  {{ data.item.first_name }} {{ data.item.last_name }}
                </template>

                <template v-slot:cell(total_amount)="data">
                  {{ data.item.total_amount | currency }}
                </template>
                <template v-slot:cell(opt_in)="data">
                  {{
                    data.item.opt_in
                      | datetime($store.getters['auth/dateFormat'])
                  }}
                </template>
                <template v-slot:cell(opt_out)="data">
                  <div class="d-flex">
                    <div style="width: 30px">
                      <span v-if="data.item.opt_out" v-b-tooltip.hover :title="getOptOutTooltip(data.item, 'sms')">
                        <i class="uil uil-comment text-danger"></i>
                      </span>
                    </div>
                    <div style="width: 30px">
                      <span v-if="data.item.email_opt_out" v-b-tooltip.hover :title="getOptOutTooltip(data.item, 'email')">
                        <i class="uil uil-envelope text-danger"></i>
                      </span>
                    </div>
                  </div>
                </template>
                <template v-slot:cell(birthday)="data">
                  <template v-if="data.item.birthday">
                  {{
                    data.item.birthday
                      | moment($store.getters['auth/birthdayFormat'])
                  }}
                  </template>
                </template>
                <template v-slot:cell(created_at)="data">
                  {{
                    data.item.created_at
                      | datetime(
                        `${$store.getters['auth/dateFormat']} HH:mm:ss`
                      )
                  }}
                </template>
                <template v-slot:cell(is_verified)="data">
                  <feather
                    v-if="data.item.is_verified"
                    type="check"
                    class="icon-dual-primary"
                  />
                </template>
                <template v-slot:cell(actions)="data">
                  <div class="table-actions-group" style="min-width: 250px">
                    <router-link
                      :to="{
                        name: 'business.contacts.edit',
                        params: { id: data.item.id },
                      }"
                      class="btn btn-outline-dark"
                    >
                      <i class="uil uil-edit"></i> Edit
                    </router-link>
                    <b-dropdown
                      variant="outline-dark"
                      right
                    >
                      <template v-slot:button-content>
                        <i class="uil uil-angle-down"></i>
                      </template>
                      <template v-if="!data.item.twilio_opt_out">
                        <b-dropdown-item 
                          v-b-tooltip.hover
                          title="This applies to both sms and email service."
                          style="cursor: pointer"
                          @click="toggleOptOut(data.item)"
                        >
                          <i class="uil uil-comment-alt-upload mr-2"></i>
                          {{ data.item.opt_out ? 'Opt In' : 'Opt Out' }}
                        </b-dropdown-item>
                      </template>
                      <template v-else>
                        <b-dropdown-item
                          v-b-tooltip.hover
                          :disabled="!!data.item.twilio_opt_out"
                          title="We can't Opt In this contact as customer unsubscribed on his/her side."
                          style="cursor: pointer"
                        >
                          <i class="uil uil-comment-alt-upload mr-2"></i>
                          {{ data.item.opt_out ? 'Opt In' : 'Opt Out' }}
                        </b-dropdown-item>
                      </template>
                      <b-dropdown-item
                        :disabled="loadingRemove === data.item.id"
                        variant="danger"
                        @click="destroy(data.item)"
                      >
                        <i class="uil uil-trash mr-2"></i> Delete
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </template>
              </b-table>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalContacts }} contacts
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalContacts"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showDeleteModal" title="Delete contact">
      <p v-if="selectedContact"
        >Are you sure you want to delete contact
        <b>"{{ selectedContact.id }}"</b>?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleDestroy">Delete</b-button>
      </template>
    </b-modal>

    <b-modal v-model="showBulkDeleteModal" title="Delete Selected contacts">
      <p
        >Are you sure you want to delete
        <b v-if="selectedContacts.length">({{ selectedContacts.length }})</b>
        contact<span
          v-if="!selectedContacts.length || selectedContacts.length > 1"
          >s</span
        >?
      </p>
      <p class="font-weight-bold text-danger"
        >Please be aware that You can't restore contacts once they are deleted
        from our system.</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showBulkDeleteModal = false"
          >Cancel</b-button
        >
        <b-button variant="danger" @click="handleBulkDeleteContact"
          >Delete</b-button
        >
      </template>
    </b-modal>

    <b-modal
      v-model="showBulkAssignKeywordsModal"
      title="Add Keywords To Contacts"
      size="lg"
    >
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label>Keywords</label>
            <multiselect
              v-model="selectedKeywords"
              :options="keywords"
              :multiple="true"
              track-by="id"
              label="name"
              placeholder="Select keywords"
            ></multiselect>
          </div>
        </div>
      </div>

      <template v-slot:modal-footer>
        <b-button
          :disabled="!selectedKeywords.length"
          variant="primary"
          @click="handleBulkAssignKeywords"
          >Assign</b-button
        >
      </template>
    </b-modal>

    <b-modal v-model="isShowExportModal" title="Export contacts" size="lg" modal-class="export-contacts-modal">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <strong>Confirm you want to export contacts? </strong><br/><br/>
            <template v-if="customFields && customFields.length">
              <p>Please select custom fields you want to export with.</p>
              <div v-for="field in customFields" :key="field.id" class=" custom-checkbox  custom-checkbox-xs" >
                <b-form-checkbox v-model="selectedFields" :value="field" inline>{{ field.name }}</b-form-checkbox>
              </div>
            </template>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="light" @click="isShowExportModal = false">Close</b-button>
        <b-button variant="primary" @click="handleExport">
          <b-spinner v-if="isLoadingExport" small />
          <span v-else>Export</span>
        </b-button>
      </template>
    </b-modal>

    <b-modal v-model="isShowImportModal" title="Import contacts" size="lg" modal-class="import-contacts-modal">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <strong>You can download sample upload file <a href="/Template.xlsx" download>here</a>.</strong> <br>
            <strong>You can upload upto 5000 contacts at a time</strong>
          </div>
          <div class="mt-1 mb-4">
            <h5 class="text-primary">Did these contacts consent to receive SMS marketing messages?</h5>
            <p>To follow anti-spam laws & and best practices, your contacts must provide explicit consent to receive SMS messages.</p>
            <div class="form-group">
              <b-form-radio v-model="importContact.isVerified" name="isVerified" :value="true" class="d-block custom-checkbox-s">Yes, update all imported contacts to Verified Status</b-form-radio>
              <b-form-radio v-model="importContact.isVerified" name="isVerified" :value="false" class="d-block custom-checkbox-s mt-3">No, import without updating Verified Status</b-form-radio>
            </div>
            <div v-if="!importContact.isVerified" class="alert alert-warning mt-3" role="alert">
              New contacts will be imported without consent to receive SMS marketing messages. You will not be able to send them SMS messages.
              Verified status for existing contacts will remain unchanged.
            </div>
          </div>
          <div class="form-group">
            <label>Keyword (optional)</label>
            <multiselect
              v-model="importContact.keywords"
              :options="keywords"
              :multiple="true"
              track-by="id"
              label="name"
              placeholder="Select keywords"
            ></multiselect>
          </div>
          <div class="form-group">
            <label>
              Automation (optional) 
              <span v-b-tooltip.hover class="cursor-pointer" title="Run selected automations when contact is imported." style="vertical-align: middle;">
                <i  class="uil uil-question-circle"></i>
              </span>
            </label>
            <multiselect
              v-model="selectedAutomation"
              :options="automations"
              :multiple="false"
              track-by="id"
              placeholder="Select automation"
              label="name"
            ></multiselect>
          </div>
          <div class="form-group">
            <UploadFile
            v-model="fileName"
            accept=".xlsx, .xls, .csv"
            label="Select File to Upload"
            @upload="onUploadFile"
          />
          </div>
          <div class="form-group">
            <b-form-checkbox v-model="importContact.agree" size="xs" inline>
              I have consent from my customer. The Telephone Consumer Protection Act (TCPA) requires companies to have prior 
              express written consent digitally or electronically from each contact on your subscriber list in order to text the contact.
            </b-form-checkbox>
          </div>
        </div>
      </div>

      <template v-slot:modal-footer>
        <b-button
          variant="light"
          class="mr-2"
          @click="handleCloseImportModal"
          >Close</b-button
        >
        <b-button
          :disabled="!importContact.agree || !importContact.attach || isLoadingImport"
          variant="primary"
          @click="handleImportContacts"
          >
            <b-spinner v-if="isLoadingImport" class="align-middle" small></b-spinner>
            <span v-else>Import</span>
          </b-button
        >
      </template>
    </b-modal>

    <b-modal v-model="isShowAutomationAddModal" title="Run Automation">
      <div class="form-group">
        <label>Automation</label>
        <multiselect
          v-model="selectedAutomation"
          :options="automations"
          :multiple="false"
          track-by="id"
          placeholder="Select automation"
          label="name"
        ></multiselect>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" :disabled="isLoadingStartAutomation || !selectedAutomation" @click="handleStartAutomation">
          <b-spinner v-if="isLoadingStartAutomation" small />
          <span v-else>Start</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import axios from 'axios'

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      datatable: {
        columns: [
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'opt_in',
          sort_desc: true,
          sort_direction: 'asc',
          keyword: '',
          filter: '',
          automation: undefined,
        },
      },
      importContact: {
        isVerified: false,
      },
      keywords: [],
      showDeleteModal: false,
      selectedContact: null,
      loadingRedemptions: false,
      loadingImpersonate: false,
      loadingRemove: null,
      selectedContacts: [],
      showBulkDeleteModal: false,
      showBulkAssignKeywordsModal: false,
      selectedKeywords: [],
      selectedFields: [],
      isShowImportModal: false,
      isShowExportModal: false,
      fileName: null,
      isLoadingImport: false,
      isLoadingExport: false,
      isShowAutomationAddModal: false,
      automations: [],
      selectedAutomation: null,
      isLoadingStartAutomation: false,
      filterText: undefined,
    }
  },

  computed: {
    contacts() {
      return this.$store.getters['contact/all'] || []
    },

    totalContacts() {
      return this.$store.getters['contact/total']
    },

    user() {
      return this.$store.getters['auth/user']
    },
    isDoubleOptin() {
      return this.user && this.user.business && this.user.business.double_optin
    },
    customFields(){
      return this.$store.getters['customField/all'] || []
    }
  },

  mounted() {
    if (this.$route.query.automation) {
      let tmp = this.$route.query.automation.split('_')
      this.datatable.queries.automation = tmp[0]
      this.filterText = `Automation #${tmp[0]}: <b>${tmp[1]}</b>`
    } else {
      this.datatable.queries.automation = undefined
      this.filterText = undefined
    }
    let columns = [
      { key: 'id', label: '#', thStyle: { width: "60px" }, },
      { key: 'name', label: 'Name' },
      { key: 'email' },
      { key: 'phone' },
      { key: 'birthday' },
      { key: 'total_amount', sortable: true },
      { key: 'opt_in', sortable: true, sortDirection: 'desc' },
      { key: 'opt_out', sortable: true, sortDirection: 'desc', thStyle: { width: "100px" },},
    ];

    if (this.isDoubleOptin)
      columns.push({ key: 'is_verified', label: 'SMS Verified' });
    columns.push({ key: 'actions' })
    this.datatable.columns = columns

    this.getKeywords()
    this.getContacts()
  },

  methods: {
    clickRest() {
      this.$router.replace({ query: {} })
      this.filterText = undefined
      this.resetQuery()
      this.getContacts();
    },
    getOptOutTooltip(item, type = 'sms') {
      if (type === 'sms' && item.opt_out)
        return `This contact has opted out from sms service on ` + this.$options.filters.datetime(item.opt_out, this.$store.getters['auth/dateFormat'])

      if (type === 'email' && item.email_opt_out)
        return `This contact has opted out from email service on ` + this.$options.filters.datetime(item.email_opt_out, this.$store.getters['auth/dateFormat'])
      return undefined;
    },
    resetQuery() {
      this.datatable.queries = {
        per_page: 10,
        page: 1,
        status: 'active',
        search: null,
        sort_by: 'opt_in',
        sort_desc: true,
        sort_direction: 'asc',
        keyword: '',
        filter: '',
        automation: undefined,
      }
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.sort_direction = args.sortDirection
      this.datatable.queries.page = 1
      this.getContacts()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getContacts()
    },

    onBulkStartAutomation() {
      this.getAutomations()
      this.isShowAutomationAddModal = true
    },

    getAutomations() {
      this.$store.dispatch('automation/all')
        .then(automations => {
          this.automations = automations
        })
        .catch(() => {

        })
    },

    handleStartAutomation() {
      this.isLoadingStartAutomation = true

      this.$store.dispatch('contact/bulkStartAutomation', {
        contactIds: this.selectedContacts,
        automationId: this.selectedAutomation.id,
      })
        .then((automations) => {
          this.isShowAutomationAddModal = false
          this.isLoadingStartAutomation = false
          this.selectedAutomation = null
        })
        .catch(() => {
          this.isLoadingStartAutomation = false
        })
    },

    getContacts() {
      this.loadingRedemptions = true

      this.$store
        .dispatch('contact/getAll', this.datatable.queries)
        .then(() => {
          this.loadingRedemptions = false
        })
        .catch(() => {
          this.loadingRedemptions = false
        })
    },

    destroy(contact) {
      this.showDeleteModal = true
      this.selectedContact = contact
    },

    toggleOptOut(contact) {
      let param = {
        id: contact.id,
      }

      this.$store
        .dispatch('contact/toggleOptOut', param)
        .then(() => {})
        .catch(() => {})
    },

    handleVerify(contact) {
      this.loadingRemove = contact.id
      let param = {
        ...contact,
        is_verified: true
      }
      this.$store
        .dispatch('contact/update', param)
        .then(() => {
          this.loadingRemove = null
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },

    handleDestroy() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedContact.id

      this.$store
        .dispatch('contact/remove', this.selectedContact.id)
        .then(() => {
          this.$store
            .dispatch('user/getLiveData')
          this.loadingRemove = null
        })
        .catch(() => {
          this.loadingRemove = null
        })
    },

    getKeywords() {
      this.$store
        .dispatch('coupon/getKeywords')
        .then((keywords) => {
          this.keywords = keywords.map((c) => {
            return {
              id: c.id,
              name: c.name
            }
          })
        })
        .catch(() => {})
    },

    toggleBulkContacts(event) {
      if (event.target.checked) {
        this.selectedContacts = this.contacts.map((c) => c.id)
      } else {
        this.selectedContacts = []
      }
    },

    bulkDeteteContacts() {
      this.showBulkDeleteModal = true
    },

    bulkAssignKeywords() {
      this.showBulkAssignKeywordsModal = true
      this.selectedKeywords = []
    },

    handleBulkDeleteContact() {
      this.$store
        .dispatch('contact/bulkRemove', {
          contactIds: this.selectedContacts,
          filters: this.datatable.queries,
        })
        .then(() => {
          this.showBulkDeleteModal = false
          this.selectedContacts = []
          this.getContacts()
        })
        .catch(() => {})
    },

    handleBulkAssignKeywords() {
      this.$store
        .dispatch('contact/bulkAssignKeywords', {
          keywords: this.selectedKeywords.map(item => item.id),
          contactIds: this.selectedContacts,
          filters: this.datatable.queries,
        })
        .then(() => {
          this.showBulkAssignKeywordsModal = false
        })
        .catch(() => {})
    },

    handleExport() {
      this.isLoadingExport = true
      this.isShowExportModal = false
      this.datatable.queries.selectedCustomFields = this.selectedFields
      axios.get('/contacts/export', { responseType: 'blob', params: this.datatable.queries })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/xlsx' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'contact.xlsx'
          link.click()
          URL.revokeObjectURL(link.href)
          this.isLoadingExport = false
        }).catch()
    },
    handleExportModalOpen(){
      this.isShowExportModal = true
      this.getCustomFields()
    },
    getCustomFields() {

      this.isLoadingExport = true
      this.$store
          .dispatch('customField/getAll', this.datatable.queries)
          .then(() => {
            this.selectedFields = this.customFields
            this.isLoadingExport = false
          })
          .catch(() => {
            this.isLoadingExport = false
          })
    },

    handleShowModalImport() {
      this.selectedAutomation = null
      this.getAutomations()
      this.isShowImportModal = true
      this.importContact = {
        isVerified: false,
      }
      this.fileName = null
    },

    handleCloseImportModal() {
      this.isShowImportModal = false
    },

    handleImportContacts() {
      let uploadForm = new FormData()
      uploadForm.append('attach', this.importContact.attach)
      uploadForm.append('keywords', this.importContact.keywords ? this.importContact.keywords.map(item => item.id) : [])
      uploadForm.append('automationId', this.selectedAutomation ? this.selectedAutomation.id : null)
      uploadForm.append('isVerified', this.importContact.isVerified)
      
      this.isLoadingImport = true
      
      this.$store
        .dispatch('contact/importContacts', uploadForm)
        .then((res) => {
          this.isLoadingImport = false
          this.handleCloseImportModal()
          this.getContacts();
        })
        .catch(() => {
          this.isLoadingImport = false
          this.handleCloseImportModal()
        })
    },

    onUploadFile(file) {
      this.importContact.attach = file
    },
  },
}
</script>

<style lang="scss">
</style>